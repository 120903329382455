<template>
  <div >
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="teacher-notice">
      <div class="top">
        <div>
          <Button type="primary" @click="addNotice">添加新通知</Button>
        </div>
        <Input v-model="keyword"
               @on-enter="searchList"
               @on-search="searchList"
               :placeholder="$t('user_search_tip')"
               clearable
               search
               :enter-button="true"
               style="width: 300px"
        />
      </div>
      <div class="search">
        <div class="search-item">
          <div class="name">
            通知途径:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curSendType == item.id ? 'active' : ''" v-for="item in sendTypeArr" :key="item.value" @click="changeSearch(item,'curSendType')">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            通知类型:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curType == item.id ? 'active' : ''" v-for="item in typeArr" :key="item.value" @click="changeSearch(item,'curType')">{{ item.name }}</div>

            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            发送状态:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curStatuse == item.id ? 'active' : ''" v-for="item in statuseArr" :key="item.value" @click="changeSearch(item,'curStatuse')">{{ item.name }}</div>

            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <Table :columns="columns" :data="data" :loading="loading"></Table>
      </div>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
    </div>
    <Modal
        v-model="checkModal"
        title="查看"
        @on-cancel="closeCheckModal"
        width="1100px"
    >
      <div class="search">
        <div class="search-item">
          <div class="name">
            发送状态:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCheckStatuse == item.id ? 'active' : ''" v-for="item in checkStatuseArr" :key="item.value" @click="changeCheckSearch(item,'curCheckStatuse')">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="table" >
        <Table :columns="checkColumns" :data="checkData" :loading="checkLoading"></Table>
      </div>
      <div class="page" >
        <Page :total="checkTotal" @on-change="changeCheckPage" @on-page-size-change="changeCheckPageSize" :current="checkPage" show-total show-sizer show-elevator></Page>
      </div>
      <div slot="footer">
        <Button @click="closeCheckModal">取消</Button>
        <Button type="primary" @click="confirmCheck">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import util from '@/utils/tools.js';

export default {
  name: "authenticationVerify.vue",
  data(){
    return {
      columns:[
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '通知途径',
          key: 'send_type',
          render:(h,params)=>{
            return h('span', this.sendTypes[params.row.send_type]);
          }
        },
        {
          title: '通知类型',
          key: 'id',
          render:(h,params)=>{
            return h('span', this.types[params.row.type]);
          }
        },
        {
          title: '通知人数',
          key: 'count',
        },
        {
          title: '发送状态',
          key: 'id',
          render:(h,params)=>{
            return h('span', this.statuses[params.row.status]);
          }
        },
        {
          title: '创建时间',
          key: 'id',
          render:(h,params)=>{
            return h('div', [
                  h('span',util.timeFormatter(new Date(+params.row.created_at*1000), 'yyyy-MM-dd hh:mm')),
                ]
            );
          }
        },
        {
          title: '发送时间',
          key: 'id',
          render:(h,params)=>{
            return h('div', [
                  h('p',util.timeFormatter(new Date(+params.row.send_start_time*1000), 'yyyy-MM-dd hh:mm')),
                  //params.row.send_end_time && params.row.send_end_time != '0' ? h('p', util.timeFormatter(new Date(+params.row.send_end_time*1000), 'yyyy-MM-dd hh:mm')) : ''
                ]
            );
          }
        },
        {
          title: '操作',
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                },
                on: {
                  click: () => {
                    this.check(params.row);
                  }
                }
              }, '查看'),
              params.row.status == '0' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                },
                on: {
                  click: () => {
                    this.edit(params.row);
                  }
                }
              }, '编辑') :'',
            ]);
          }
        },
      ],
      data:[],
      page:1,
      pageSize:10,
      total:0,
      keyword:'',
      loading:false,
      sendTypes:{},
      statuses:{},
      types:{},
      sendTypeArr:[],
      statuseArr:[],
      typeArr:[],
      curSendType:'-1',
      curStatuse:'-1',
      curType:'-1',
      checkModal:false,
      checkColumns:[
        {
          title: '用户ID',
          key: 'user_id',
        },
        {
          title: '姓名',
          key: 'name',
          render:(h,params)=>{
            return h('span', params.row.user.nickname);
          }
        },
        {
          title: '发送状态',
          key: 'id',
          render:(h,params)=>{
            return h('span', this.checkStatuseObj[params.row.status]);
          }
        },
        {
          title: '备注',
          key: 'messasge',
        },
        // {
        //   title: '操作',
        //   render:(h,params)=>{
        //     return h('div', [
        //       h('Button', {
        //         props: {
        //           type: 'text',
        //           size: 'small'
        //         },
        //         style: {
        //           marginRight: '5px',
        //         },
        //         on: {
        //           click: () => {
        //
        //           }
        //         }
        //       }, '重新发送'),
        //     ]);
        //   }
        // },
      ],
      checkData:[],
      checkLoading:false,
      curData:{},
      checkPage:1,
      checkPageSize:10,
      checkTotal:0,
      curCheckStatuse:'-1',
      checkStatuseArr:[],
      checkStatuseObj:{},
    }
  },
  created(){
    this.getList();
  },
  components:{
  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        status:this.curStatuse,
        type:this.curType,
        send_type:this.curSendType
      }
      this.loading = true;
      this.api.user.teacherNoticeList(params).then((res)=>{
        this.loading = false;
        this.sendTypes = res.send_types;
        this.sendTypeArr = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.send_types){
          this.sendTypeArr.push({
            name:res.send_types[name],
            id:name
          })
        }
        this.statuses = res.statuses;
        this.statuseArr = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.statuses){
          this.statuseArr.push({
            name:res.statuses[name],
            id:name
          })
        }
        this.types = res.types;
        this.typeArr = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.types){
          this.typeArr.push({
            name:res.types[name],
            id:name
          })
        }

        this.data = res.list;
        this.total = Number(res.count);

        this.data.forEach((item)=>{

        })
      }).catch((err)=>{
        this.loading = false;

      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    searchList(){
      this.page = 1;
      this.getList();
    },
    changeSearch(data,name){
      this[name] = data.id;
      this.page = 1;
      this.getList();
    },
    changeCheckSearch(data,name){
      this[name] = data.id;
      this.page = 1;
      this.getDataInfo();
    },
    addNotice(){
      this.$router.push({
        path:'/user/add-teacher-notice'
      })
    },
    check(data){
      this.checkModal = true;
      this.curData = data;
      this.getDataInfo();
    },
    getDataInfo(){
      let params = {
        notice_id:this.curData.id,
        page:this.checkPage,
        pageSize:this.checkPageSize,
        status:this.curCheckStatuse,
      };
      this.checkLoading = true;
      this.api.user.teacherNoticeItemList(params).then((res)=>{
        this.checkLoading = false;
        this.checkData = res.list;

        this.checkStatuseArr = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.statuses){
          this.checkStatuseArr.push({
            name:res.statuses[name],
            id:name
          })
        }
        this.checkStatuseObj = res.statuses;
      }).catch((err)=>{
        this.checkLoading = false;
      })
    },
    closeCheckModal(){
      this.checkModal = false;
    },
    confirmCheck(){
      this.checkModal = false;
    },
    changeCheckPage(page){
      this.checkPage = page;
      this.getDataInfo();
    },
    changeCheckPageSize(pageSize){
      this.checkPageSize = pageSize;
      this.checkPage = 1;
      this.getDataInfo();
    },
    edit(data){
      this.curData = data;
      this.$router.push({
        path:'/user/add-teacher-notice',
        query:{
          id:this.curData.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.teacher-notice{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  font-size: 14px;

  .top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}
.search{
  margin-top: 20px;
  margin-bottom: 20px;
  .search-item{
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;

    .name{
      padding: 5px 0;
      width: 75px;
      text-align: right;
    }
    .cont{
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          padding: 5px 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
          border-radius: 4px;
        }
      }
      .more{
        padding: 5px 0;
        width: 80px;
        text-align: right;
        cursor: pointer;
        .more-icon{
          transition: transform 0.3s linear;
        }
      }
    }
  }
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
